<template>
  <b-card
      footer-class="text-muted"
  >
    <b-overlay
        :show="show"
        no-wrap
    />
    <div class="p-2">
      <h2>Cita de: {{ partner.name }}</h2>
    </div>
    <b-form
        class="p-2"
        @submit.prevent="createAppointment('edit')"
    >
      <b-form-group
          :label="place_active ? 'Salas' : 'Medicos'"
          label-for="h-email"
      >
        <v-select
            @input="medicalVerify()"
            v-model="select_medical"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="value"
            :options="medicals"
        />
      </b-form-group>
      <b-form-group
          label-for="start-date"
      >
        <label for=""><span style="margin-left: 20px">Hora de inicio</span> <span style="margin-left: 20px">~</span>
          <span style="margin-left: 20px">Hora de salida</span></label>
        <date-picker
            v-model="value2"
            format="YYYY-MM-DD HH:mm"
            type="datetime"
            :show-time-header="true"
            @close="changeDate()"
            confirm
            style="width: 100%"
            :disabled-date="(date) => date <= disabledBefore"
            range
            placeholder="Seleccione las fechas"
        >
          <template #header>
            <div style="text-align: left">
              <b-row>
                <b-col cols="6">
                  <h3 style="text-align: center"> Inicio</h3>
                </b-col>
                <b-col cols="6">
                  <h3 style="text-align: center">Fin</h3>
                </b-col>
              </b-row>
            </div>
          </template>
        </date-picker>
      </b-form-group>
      <b-form-group
          label="Proceso Clinica"
      >
        <b-form-select
            v-model="appointment.appoint_state_clinical"
            :options="clinical_states"
        />
      </b-form-group>
      <VoiceInput label="Notas" placeholder="Notas" rows="3" id="textarea-default1" v-model="appointment.description" />

      <b-row class="mt-3">
        <b-col cols="6" v-if="new_file">
          <b-form-group
              v-if="!verified_treatment"
              label="Subir Archivo"
              label-for="h-email"
          >
            <b-form-file

                @change="handleImage(1)"
                id="file1"
                placeholder="Subir archivo."
                drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" v-if="new_file2">
          <b-form-group
              v-if="!verified_treatment2"
              label="Subir Archivo"
              label-for="h-email"
          >
            <b-form-file

                @change="handleImage(2)"
                id="file2"
                placeholder="Subir archivo."
                drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" v-if="new_file3">
          <b-form-group
              v-if="!verified_treatment3"
              label="Subir Archivo"
              label-for="h-email"
          >
            <b-form-file

                @change="handleImage(3)"
                id="file3"
                placeholder="Subir archivo."
                drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" v-if="new_file4">
          <b-form-group
              v-if="!verified_treatment4"
              label="Subir Archivo"
              label-for="h-email"
          >
            <b-form-file

                @change="handleImage(4)"
                id="file4"
                placeholder="Subir archivo."
                drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-button
          class="mt-1"
          v-if="available_appointent"
          v-ripple.400="'rgba(25, 17, 46, 0.15)'"
          type="submit"
          variant="outline-secondary"
      >
        Guardar Cambios
      </b-button>
      <br>
      <div>
        <b-row>
          <b-col cols="4" v-if="verified_treatment">
            <a @click="deleteFileAppointment(appointment.id,1)" style="text-align: right">
              <feather-icon icon="TrashIcon" style="color: darkred" size="20"/>
            </a>
            <a @click="downloads(appointment.id, appointment.treatment_file, appointment.appoint_date,partner.name, 1)"
               style="text-align: center">
              <feather-icon icon="FolderIcon" size="100"/>
              <br>Descargar Archivo </a>
          </b-col>
          <b-col cols="4" v-if="verified_treatment2">
            <a @click="deleteFileAppointment(appointment.id,2)" style="text-align: right">
              <feather-icon icon="TrashIcon" style="color: darkred" size="20"/>
            </a>
            <a @click="downloads(appointment.id, appointment.treatment_file2, appointment.appoint_date,partner.name, 2)"
               style="text-align: center">
              <feather-icon icon="FolderIcon" size="100"/>
              <br>Descargar Archivo </a>
          </b-col>
          <b-col cols="4" v-if="verified_treatment3">
            <a @click="deleteFileAppointment(appointment.id,3)" style="text-align: right">
              <feather-icon icon="TrashIcon" style="color: darkred" size="20"/>
            </a>
            <a @click="downloads(appointment.id, appointment.treatment_file3, appointment.appoint_date,partner.name, 3)"
               style="text-align: center">
              <feather-icon icon="FolderIcon" size="100"/>
              <br>Descargar Archivo </a>
          </b-col>
          <b-col cols="4" v-if="verified_treatment4">
            <a @click="deleteFileAppointment(appointment.id,4)" style="text-align: right">
              <feather-icon icon="TrashIcon" style="color: darkred" size="20"/>
            </a>
            <a @click="downloads(appointment.id, appointment.treatment_file4, appointment.appoint_date,partner.name, 4)"
               style="text-align: center">
              <feather-icon icon="FolderIcon" size="100"/>
              <br>Descargar Archivo </a>
          </b-col>
        </b-row>

      </div>

    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BForm, BOverlay, BSidebar, BFormCheckbox, BFormInvalidFeedback, BFormSelect, BFormFile

} from 'bootstrap-vue'
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive";
import flatPickr from 'vue-flatpickr-component'
import {mapGetters, mapActions} from "vuex";
import {VueAutosuggest} from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {email, required, url} from "@core/utils/validations/validations";
import VoiceInput from "@/components/VoiceInput.vue";

export default {
  name: "UpdateAppoitment",
  components: {
    VoiceInput,
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver, VueAutosuggest, BFormSelect, BOverlay, BFormFile,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCard,
    BLink,
    BRow,
    BCol, DatePicker
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      verified_treatment: false,
      verified_treatment2: false,
      verified_treatment3: false,
      verified_treatment4: false,
      verified_treatment_edit: false,
      verified_treatment_edit2: false,
      verified_treatment_edit3: false,
      verified_treatment_edit4: false,
      new_file: false,
      new_file2: false,
      new_file3: false,
      new_file4: false,
      select_medical: {id: '', value: ''},
      select_group: {id: '', value: ''},
      select_price_list: {id: '', value: ''},
      sources: [{value: '', text: 'Seleccione una opción'},
      ],
      patients: [],
      value2: [],
      sidebar_status: false,
      show: false,
      groups: [],
      pricelists: [],
      medicals: [],
      available_appointent: true,
      place_active: false,
      clinical_states: [
        {value: 'p', text: 'Programa'},
        {value: 'r1', text: 'Revisión coordinador médico'},
        {value: 'r2', text: 'Revisión enfermería'},
        {value: 'rc', text: 'Revisión Conjunta'},
        {value: 'e', text: 'Envío de aseguradora/ cotizaciones'},
        {value: 'f', text: 'Farmacia'},
      ],
      partner: {},
      disabledBefore: '',
      appointment: {
        id: null,
        customer: '',
        appoint_person_id: '',
        description: '',
        app_dt_start: '',
        app_dt_stop: '',
        appoint_date: '',
        appoint_state: 'new',
        appoint_state_clinical: "p",
        appoint_group_id: '',
        time_slot: '',
        time_slot_day: '',
        source: '',
        treatment_file: null,
        treatment_file2: null,
        treatment_file3: null,
        treatment_file4: null
      },
      required,
      email,
      url,
    }
  },
  async created() {

    const day = moment().subtract(1, 'day').format('DD-MM-YYYY')
    this.disabledBefore = this.$moment(day, 'DD-MM-YYYY')
    this.sources = []
    this.patients = []
    this.appointment = {}
    this.verified_treatment = false
    this.verified_treatment_edit = false
    this.new_file = false
    this.appointment.customer = ''
    this.select_medical = {id: '', value: ''}
    this.select_group = {id: '', value: ''}
    this.select_price_list = {id: '', value: ''}
    this.appointment.id = this.$route.params.id
    await this.verifyAppointment(this.appointment.id)
  },
  methods: {
    ...mapActions('patient', ['findPatientsSearchSelect']),
    ...mapActions('calendarStoreModule', ['fetchEvents']),
    ...mapActions('appointment', ['findAppointmentDoctor', 'findAppointmentPriceList', 'findAppointmentGroups', 'appointmentAvailable',
      'findSlotVerify', 'createAppointmentSend', 'findAppointmentSource', 'findAppointmentUserOne', 'findApointeeSearch', 'downloadFileAppointment', 'deleteFile', 'downloadFileAppointmentAll', 'deleteFileAll']),
    async handleImage(id) {
      const file = document.querySelector(`#file${id}`).files[0]
      const file_convert = await this.getBase64(file)
      file_convert.onload = (event) => {
        if (id === 1) {
          this.appointment.treatment_file = event.target.result
        } else if (id === 2) {
          this.appointment.treatment_file2 = event.target.result
        } else if (id === 3) {
          this.appointment.treatment_file3 = event.target.result
        } else {
          this.appointment.treatment_file4 = event.target.result
        }

        /* this.company.file_key = event.target.result.split(',')[1]*/
      };
    },
    async changeDate() {
      this.appointment.app_dt_start = moment(this.value2[0]).format('YYYY-MM-DD HH:mm:ss')
      this.appointment.app_dt_stop = moment(this.value2[1]).format('YYYY-MM-DD HH:mm:ss')
      await this.verifyDate('')
    },
    async deleteFileAppointment(id, file_number) {
      const send = {
        id, file_number
      }
      const response = await this.deleteFileAll(send)
      this.show = true
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se borro el archivo`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        if (parseInt(file_number) === 1) {
          this.new_file = true
          this.verified_treatment = false
        } else if (parseInt(file_number) === 2) {
          this.new_file2 = true
          this.verified_treatment2 = false
        } else if (parseInt(file_number) === 3) {
          this.new_file3 = true
          this.verified_treatment3 = false
        } else {
          this.new_file4 = true
          this.verified_treatment4 = false
        }

      } else {

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al borrar el archivo',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      }
      this.show = false
    },
    async downloads(id, file, name, partner, file_number) {
      const [, type] = file.split(';')[0].split('/');
      const send = {
        id,
        type,
        name,
        partner,
        file_number
      }
      await this.downloadFileAppointmentAll(send)
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async verifySource() {
      let name_source;
      for (const a of this.sources) {
        if (a.value === this.appointment.source) {
          name_source = a.text
        }
      }
      if (name_source === 'Tratamiento') {
        let new_group = []
        const response = await this.findAppointmentGroups()
        for (const a of response) {
          if (a.is_place) {
            this.select_group = {id: a.id, value: a.group_name}
            new_group.push({
              id: a.id,
              value: a.group_name,
              verifed: a.is_place
            })
          }
        }
        this.groups = []
        this.groups = new_group
        await this.searchGroup(this.select_group.id)
        this.appointment.appoint_group_id = this.select_group.id
        this.place_active = true
        this.verified_treatment = true

      } else {
        let new_group = []
        this.medicals = []
        this.appointment.treatment_file = null
        this.select_group = {id: '', value: ''}
        const response = await this.findAppointmentGroups()
        for (const a of response) {
          if (!a.is_place) {
            new_group.push({
              id: a.id,
              value: a.group_name,
              verifed: a.is_place
            })
          }

        }
        this.verified_treatment = false
        this.groups = []
        this.groups = new_group
        this.place_active = false
      }
    },
    async searchGroup(id) {
      const response = await this.findApointeeSearch(id);
      this.medicals = []
      for (const a of response) {
        this.medicals.push({
          id: a.ResPartnerAppointment.id,
          value: a.ResPartnerAppointment.name,
        })
      }

    },
    async verifyAppointment(id) {
      const response = await this.findAppointmentUserOne(id);
      this.sources.push({
        value: response.AppointmentSource.id, text: response.AppointmentSource.name
      })
      const dat1 = this.resHoursOdoo(response.app_dt_start)
      const dat2 = this.resHoursOdoo(response.app_dt_stop)
      this.appointment = response
      this.value2.push(new Date(dat1))
      this.value2.push(new Date(dat2))
      this.appointment.app_dt_start = dat1
      this.appointment.app_dt_stop = dat2
      this.appointment.source = response.source
      this.partner = response.ResPartner;
      this.select_medical.id = response.ResPartnerAppointment.id
      this.select_medical.value = response.ResPartnerAppointment.name
      this.select_group.id = response.AppointmentGroup.id
      this.select_group.value = response.AppointmentGroup.group_name
      await this.verifySource()
      if (response.AppointmentGroup.is_place) {
        if (response.treatment_file) {
          this.verified_treatment = true
        } else {

          this.new_file = true
          this.verified_treatment = false
        }
        if (response.treatment_file2) {
          this.verified_treatment2 = true
        } else {
          this.new_file2 = true
          this.verified_treatment2 = false
        }
        if (response.treatment_file3) {
          this.verified_treatment3 = true
        } else {
          this.new_file3 = true
          this.verified_treatment3 = false
        }
        if (response.treatment_file4) {
          this.verified_treatment4 = true
        } else {
          this.new_file4 = true
          this.verified_treatment4 = false
        }
      }

      delete this.appointment.ResPartnerAppointment
      delete this.appointment.ResPartner
      delete this.appointment.ProductPricelist
      delete this.appointment.AppointmentGroup
      delete this.appointment.AppointmentSource
    },
    async verifyDate(type) {
      if (this.appointment.app_dt_start) {
        const separate = this.appointment.app_dt_start.split(' ')
        this.appointment.appoint_date = separate[0]
        this.appointment.time_slot_day = this.dateString(separate[0])
        this.appointment.time_slot = await this.getSlot(this.appointment.time_slot_day)
        if (!this.appointment.time_slot) {
          this.available_appointent = false
          return
        }
        if (this.appointment.app_dt_start && this.appointment.app_dt_stop && this.appointment.appoint_person_id) {
          console.log(this.appointment.app_dt_start)
          console.log(this.appointment.app_dt_stop)

          await this.medicalVerify()
        }
      }

    },
    async getSlot(day) {
      if (day) {
        const response = await this.findSlotVerify(day.toLowerCase());
        if (response) {
          this.available_appointent = true
          return response
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Este dia no esta disponible',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          this.available_appointent = false
          return ''
        }
      }


    },
    async createAppointment(appointment) {

      let res;
      let res_error;
      if (appointment === 'create') {
        res = 'creada'
        res_error = 'crear'
      } else {
        res = 'editada'
        res_error = 'editar'
      }
      this.show = true
      const response = await this.createAppointmentSend(this.appointment)
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Cita ${res}`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        this.appointment = {}
        this.partner = {}
        if (!this.place_active) {
          await this.$router.push('/citas')
        } else {
          await this.$router.push('/salas')
        }

      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error al ${res_error} cita`,
            icon: 'error',
            variant: 'warning',
          },
        });
        this.show = false

      }
    },
    async patientVerify(item) {
      this.appointment.customer = item.item.id
    },
    async getPatientSelect(text) {

      if (text === '' || text === undefined) {
        this.patients = []
        return
      }
      this.patients = []
      let cou = []
      const response = await this.findPatientsSearchSelect(text);
      for (const a of response) {
        cou.push({
          id: a.id,
          name: a.name,
        })
      }
      this.patients = [{
        data: cou,
      }]

    },
    async medicalVerify() {
      this.appointment.appoint_person_id = this.select_medical.id;
      if (this.appointment.app_dt_start && this.appointment.app_dt_stop) {

        const send = {
          start: this.sumHoursOdoo(this.appointment.app_dt_start),
          end: this.sumHoursOdoo(this.appointment.app_dt_stop),
          medical_id: this.select_medical.id
        }
        const response = await this.appointmentAvailable(send);

        if (!response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `No hay citas en esta Fecha`,
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          this.available_appointent = response
        } else {
          this.available_appointent = response
        }
      }
    },
    async groupsVerify() {
      this.appointment.appoint_group_id = this.select_group.id;
      await this.searchGroup(this.select_group.id)
    },
    async getGroupSelect() {
      this.groups = []
      const response = await this.findAppointmentGroups()
      for (const a of response) {
        this.groups.push({
          id: a.id,
          value: a.group_name,
          verifed: a.is_place
        })
      }
    },
    async getMedicalSelect() {
      this.medicals = []
      const response = await this.findAppointmentDoctor()
      for (const a of response) {
        this.medicals.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async getSource() {
      const response = await this.findAppointmentSource()
      for (const a of response) {
        this.sources.push({
          value: a.id,
          text: a.name,
        })
      }
    },
    async getPriceListSelect() {

      this.pricelists = []
      const response = await this.findAppointmentPriceList()
      for (const a of response) {
        this.pricelists.push({
          id: a.id,
          value: a.name,
        })
      }
    }
  },


}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
